import React from "react";
import { Text } from "@atoms";

const CbCopy = ({ copy }) => {
  return (
    <section className="my-10">
      <Text variant="sm" className="prose-blog" richText>
        {copy}
      </Text>
    </section>
  );
};

export default CbCopy;
