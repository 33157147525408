import React, { useState } from "react";
import clsx from "clsx";

const Fieldset = ({
  children,
  label,
  hideLabel,
  className: _className,
  formState,
  errorState,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [formError, setFormError] = errorState || useState([]);

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
      });
    }
    return child;
  });

  return (
    <fieldset className={clsx(_className, {})}>
      {label && (
        <legend className={clsx("mb-2", { "sr-only": hideLabel })}>
          {label}
        </legend>
      )}
      {childrenWithProps}
    </fieldset>
  );
};

Fieldset.defaultProps = {};

export default Fieldset;
