import React from "react";
import clsx from "clsx";
import { Container, Image, Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";

const AppCta = ({
  image,
  heading,
  descriptor,
  appStoreUrl,
  googlePlayUrl,
  button,
  wordmark,
  overlapping = false,
}) => {
  return (
    <section
      className={clsx("pt-10 pb-10 sm:pt-20 bg-burgundy", {
        "sm:pb-28": overlapping,
        "sm:pb-20": !overlapping,
      })}
    >
      <Container label wordmark={wordmark} wordmarkColor="text-white" padding>
        <Container>
          <div className="bg-white py-10 px-10 lg:px-20 flex flex-col sm:flex-row gap-6 sm:gap-12 items-center">
            <div className="relative w-40 h-40 lg:w-52 lg:h-52">
              <Image
                image={image}
                className="rounded-full overflow-hidden"
                fill
              />
            </div>
            <div className="hidden md:block w-[2px] h-24 bg-black" />
            <div className="w-full md:flex-1 md:w-auto flex flex-col gap-4 text-center sm:text-left">
              <Text variant="lg" className="font-bold">
                {heading}
              </Text>
              <Text variant="serifDesc">{descriptor}</Text>
            </div>
            {(appStoreUrl || googlePlayUrl) && (
              <div className="flex flex-col gap-2">
                <AppLink to={appStoreUrl} className="w-32">
                  <Icon name="appStoreBadge" className="h-full w-full" />
                </AppLink>
                <AppLink to={googlePlayUrl} className="w-32">
                  <Icon name="googlePlayBadge" className="h-full w-full" />
                </AppLink>
              </div>
            )}
            {button?.url && (
              <Button to={button?.url} icon="arrow">
                {button.text || "Learn More"}
              </Button>
            )}
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default AppCta;
