import React from "react";
import clsx from "clsx";
import { Text, Button } from "@atoms";

const IconDetails = ({
  svgCode,
  heading,
  description,
  className: _className,
  button,
}) => {
  return (
    <div
      className={clsx("flex items-start text-left", _className, {
        "mb-8 flex-col gap-10": button?.url,
        "gap-6": !button?.url,
      })}
    >
      <div
        className="inline-flex h-24 w-24 shrink-0 items-center justify-center rounded-full bg-gold"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: svgCode }}
      />
      <div
        className={clsx({
          "space-y-2 sm:space-y-4": !button?.url,
          "space-y-6": button?.url,
        })}
      >
        <Text variant={button?.url ? "h6" : "h4"} className="text-text-color">
          {heading}
        </Text>
        <Text variant="body" className="text-text-color mt-1">
          {description}
        </Text>
        {button?.url && (
          <Button
            to={button.url}
            icon={
              button?.text.toLowerCase().includes("download")
                ? "download"
                : "arrow"
            }
          >
            {button?.text}
          </Button>
        )}
      </div>
    </div>
  );
};

IconDetails.defaultProps = {};

export default IconDetails;
