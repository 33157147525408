import React, { useState } from "react";
import clsx from "clsx";
import usePagination from "@hooks/usePagination";
import chunkify from "@utils/chunkify";

import { Button, Icon } from "@atoms";

const Pagination = ({
  data,
  pageSize,
  rangeLimit,
  showNavigation,
  component: Component,
  className: _className,
}) => {
  const pages = Math.ceil((data?.length || 0) / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  if (pages) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const paginationRange = usePagination({
      pages,
      currentPage,
      rangeLimit,
    });

    const nextPage = () => {
      setCurrentPage(page => (page < pages ? page + 1 : page));
    };
    const prevPage = () => {
      setCurrentPage(page => (page > 1 ? page - 1 : 1));
    };
    // use the number within the button to set the page
    const changePage = event => {
      const pageNumber = Number(event.target.textContent);
      setCurrentPage(pageNumber);
    };
    // chunk the data into pages
    const paginatedData = chunkify([...data], pages, false);

    return (
      <>
        <div className="mb-10 flex flex-col gap-10 sm:mb-16 sm:gap-16">
          {paginatedData[currentPage - 1].map((item, i) => (
            <Component order={i + 1} key={item?.uid} {...item} />
          ))}
        </div>
        {pages > 1 && (
          <div className="flex flex-wrap gap-4">
            {/* previous button */}
            {showNavigation && (
              <Button onClick={prevPage} size="2xs" color="transparentGold">
                <Icon
                  name="chevron"
                  className={clsx("h-4 w-3 rotate-180", {})}
                />
              </Button>
            )}
            {/* page number buttons */}
            {paginationRange.map(pageNumber => {
              if (pageNumber === "...") {
                return (
                  <span
                    key={pageNumber}
                    className="leading-tighter p-2 text-black"
                  >
                    {pageNumber}
                  </span>
                );
              }
              return (
                // using vanilla button here to allow for active button styling
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={pageNumber}
                  type="button"
                  className={clsx(
                    "leading-tighter rounded-full border-3 border-gold px-3 py-0.5 text-xs text-black duration-100 hover:bg-gold hover:text-white focus:ring-4 focus:ring-gold/25",
                    {
                      "bg-gold": currentPage === pageNumber,
                    }
                  )}
                  onClick={changePage}
                >
                  <span>{pageNumber}</span>
                </button>
              );
            })}
            {/* next button */}
            {showNavigation && (
              <Button onClick={nextPage} size="2xs" color="transparentGold">
                <Icon name="chevron" className="h-4 w-3" />
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
  return null;
};

Pagination.defaultProps = {
  pageSize: 9,
  rangeLimit: 1,
  showNavigation: true,
};

export default Pagination;
