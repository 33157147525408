import React from "react";
import { Icon } from "@atoms";

const ComponentName = ({ className, fitHeight }) => {
  return (
    <div className="flex items-center text-white transition-colors duration-300 ease-in-out group-hover:text-gold">
      <Icon name="logo" className={className} fitHeight={fitHeight} />
    </div>
  );
};

ComponentName.defaultProps = {
  className: "w-24",
};

export default ComponentName;
