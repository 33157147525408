import React from "react";
import clsx from "clsx";
import { Icon, Text } from "@atoms";

const CalendarControls = ({ activeState, viewState, dateState, calRef }) => {
  // state
  const [active, setActive] = activeState;
  const [view, setView] = viewState;
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = dateState;
  // refs
  const calendarRef = calRef;

  const changeView = type => {
    const calendarApi = calendarRef.current.getApi();
    if (calendarApi) {
      setView(type);
      calendarApi.changeView(type);
    }
  };

  const updateDate = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        const calDate = calendarApi.getDate();
        setDate({
          month: new Date(Date.parse(calDate)).toLocaleDateString("en-US", {
            month: "long",
          }),
          year: new Date(Date.parse(calDate)).toLocaleDateString("en-US", {
            year: "numeric",
          }),
        });
      }
    }
  };

  return (
    <div className="flex flex-col flex-wrap gap-6 sm:flex-row">
      {/* cal view buttons */}
      {active === "cal" && (
        <div className="flex flex-wrap items-end gap-6">
          <div className="flex gap-6">
            <button
              type="button"
              className={clsx("flex gap-1 py-2 text-gold duration-300", {
                "[box-shadow:_inset_0_-3px_rgb(212_184_117)]":
                  view === "dayGridMonth",
                "[box-shadow:_inset_0_-3px_rgb(212_184_117_/_0)]":
                  view !== "dayGridMonth",
              })}
              onClick={() => changeView("dayGridMonth")}
            >
              <Text className="uppercase">Month</Text>
            </button>

            <button
              type="button"
              className={clsx("flex gap-1 py-2 text-gold duration-300", {
                "[box-shadow:_inset_0_-3px_rgb(212_184_117)]":
                  view === "dayGridWeek",
                "[box-shadow:_inset_0_-3px_rgb(212_184_117_/_0)]":
                  view !== "dayGridWeek",
              })}
              onClick={() => changeView("dayGridWeek")}
            >
              <Text className="uppercase">Week</Text>
            </button>

            <button
              type="button"
              className={clsx("flex gap-1 py-2 text-gold duration-300", {
                "[box-shadow:_inset_0_-3px_rgb(212_184_117)]":
                  view === "dayGridDay",
                "[box-shadow:_inset_0_-3px_rgb(212_184_117_/_0)]":
                  view !== "dayGridDay",
              })}
              onClick={() => changeView("dayGridDay")}
            >
              <Text className="uppercase">Day</Text>
            </button>
          </div>
          {/* today + next/prev */}
          <div className="relative top-1 flex items-center gap-4 rounded-full border-3 border-gold py-2 px-4 text-gold">
            {/* prev */}
            <button
              type="button"
              onClick={() => {
                const calendarApi = calendarRef.current.getApi();
                if (calendarApi) {
                  calendarApi.prev();
                  updateDate();
                }
              }}
            >
              <span className="sr-only">previous</span>
              <Icon name="arrow" className="w-5 rotate-180" />
            </button>

            {/* today */}
            <button
              type="button"
              onClick={() => {
                const calendarApi = calendarRef.current.getApi();
                if (calendarApi) {
                  calendarApi.today();
                  updateDate();
                }
              }}
            >
              <Text className="uppercase">Today</Text>
            </button>

            {/* next */}
            <button
              type="button"
              onClick={() => {
                const calendarApi = calendarRef.current.getApi();
                if (calendarApi) {
                  calendarApi.next();
                  updateDate();
                }
              }}
            >
              <span className="sr-only">Next</span>
              <Icon name="arrow" className="w-5" />
            </button>
          </div>
        </div>
      )}

      {/* main list view */}
      <div className="flex items-end gap-6">
        <button
          type="button"
          className={clsx(
            "flex gap-1 py-2 text-gold duration-300 items-center",
            {
              "[box-shadow:_inset_0_-3px_rgb(212_184_117)]": active === "list",
              "[box-shadow:_inset_0_-3px_rgb(212_184_117_/_0)]":
                active !== "list",
            }
          )}
          onClick={() => setActive("list")}
        >
          <Icon name="list" className="w-3" />
          <Text className="uppercase">List</Text>
        </button>

        <button
          type="button"
          className={clsx(
            "flex gap-1 py-2 text-gold duration-300 items-center",
            {
              "[box-shadow:_inset_0_-3px_rgb(212_184_117)]": active === "cal",
              "[box-shadow:_inset_0_-3px_rgb(212_184_117_/_0)]":
                active !== "cal",
            }
          )}
          onClick={() => setActive("cal")}
        >
          <Icon name="calendar" className="w-3" />
          <Text className="uppercase">Calendar</Text>
        </button>
      </div>
    </div>
  );
};

export default CalendarControls;
