import React from "react";
import clsx from "clsx";
import Text from "./Text";

const Container = ({
  children,
  className: _className,
  variant = "md",
  padding = false,
  interior = false,
  label,
  wordmark,
  wordmarkColor,
  color,
  fullWidth,
  home,
  centerWordmark,
  hero,
}) => {
  const classes = [
    "mx-auto",
    {
      "max-w-2xl xl:max-w-3xl": variant === "xxs",
      "max-w-3xl xl:max-w-4xl": variant === "xs",
      "max-w-5xl xl:max-w-6xl": variant === "sm",
      "max-w-6xl xl:max-w-7xl": variant === "md" || !variant,
      "max-w-7xl xl:max-w-8xl": variant === "xl",
    },
  ];

  const px = "px-4 sm:px-6 md:px-8 lg:px-10 xl:px-16";
  // if (flush) {
  //   margin = "-mx-3 sm:-mx-6 md:-mx-8 lg:-mx-12";
  // }

  if (label) {
    return (
      <div
        className={clsx(
          "grid w-full grid-cols-1 lg:grid-cols-container xl:grid-cols-container-lg",
          _className,
          {
            "mx-auto max-w-7xl xl:max-w-8xl": !hero,
          }
        )}
      >
        <div
          className={clsx(
            "relative z-10 col-start-2 hidden justify-center lg:flex",
            {
              [color]: fullWidth,
              "py-8 lg:py-16": home || padding,
            }
          )}
        >
          <Text
            variant="wordmark"
            className={clsx("wordmark", wordmarkColor, {
              "self-start": !centerWordmark,
              "text-center": centerWordmark,
              "wordmark--pull": wordmark?.length > 10,
            })}
          >
            {wordmark}
          </Text>
        </div>
        <div
          className={clsx("w-full", color, {
            "relative col-span-full lg:col-span-3 lg:col-start-3": !home,
            "absolute inset-0 h-full": home,
          })}
        >
          {children}
        </div>
      </div>
    );
  }

  if (padding && !interior) {
    return (
      <div className="px-0 sm:px-4 lg:px-12 xl:px-8">
        <div className={clsx("w-full", classes, _className, px)}>
          {children}
        </div>
      </div>
    );
  }
  if (padding && interior) {
    return (
      <div className="-px-3 sm:px-0">
        <div className={clsx("w-full", classes, _className, px)}>
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className={clsx("w-full", classes, _className, px)}>{children}</div>
  );
};

export default Container;
