import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { Text, Button } from "@atoms";
import Card from "./Card";

dayjs.extend(weekOfYear);

const CalendarList = ({ events, setHasEvents }) => {
  const [toShow, setToShow] = useState(1);
  const thisWeek = dayjs().week();

  // sorted events
  const eventsInWeeks = useMemo(
    () =>
      events.reduce((allEvents, m, i, a) => {
        const week = dayjs(m.start).week();
        const filtered = a
          ?.filter(e => e?.status === "live")
          .filter(e => dayjs(e.start).week() === week);
        const weeksToShowTo = thisWeek + toShow;
        const insideRange = week >= thisWeek && week <= weeksToShowTo;

        if (week === thisWeek || (filtered?.length > 0 && insideRange)) {
          return {
            ...allEvents,
            [week]: filtered,
          };
        }

        return {
          ...allEvents,
        };
      }, {}),
    [toShow, events]
  );

  const hasEvents = Object.values(eventsInWeeks)?.flat()?.length > 0;

  useEffect(() => setHasEvents(hasEvents), []);

  return (
    <div className="flex flex-col gap-20">
      {Object.entries(eventsInWeeks)?.map(([key, items]) => {
        const first = parseInt(key, 10) === thisWeek;
        const w = parseInt(key, 10);

        const sameMonth =
          dayjs().week(w).startOf("w").month() ===
          dayjs().week(w).endOf("w").month();

        const nextWeekString = `${dayjs()
          .week(w)
          .startOf("w")
          .format("MMMM D")} - ${dayjs()
          .week(w)
          .endOf("w")
          .format(sameMonth ? "D" : "MMMM D")}`;

        return (
          <div
            key={`${key}-${eventsInWeeks.length}`}
            className="flex flex-col gap-10"
          >
            <Text variant="h6">{first ? "This Week" : nextWeekString}</Text>
            {!!items?.length && (
              <div className="grid grid-cols-1 gap-x-14 gap-y-20 sm:grid-cols-2">
                {items?.map(item => {
                  return (
                    <Card
                      key={`${w}-${item.uid || item.id}-${item.slug}`}
                      {...item}
                      detailsButton
                      event
                    />
                  );
                })}
              </div>
            )}
            {!items?.length && (
              <Text variant="sm">
                This week&apos;s events are done. View upcoming events below.
              </Text>
            )}
          </div>
        );
      })}

      {hasEvents && toShow <= 4 && (
        <div className="w-full flex justify-center">
          <Button onClick={() => setToShow(toShow + 20)}>Load More</Button>
        </div>
      )}

      {!hasEvents && (
        <div className="w-full flex justify-center text-center">
          <Text variant="sm">
            There are no events to display. Try selecting a different event
            category.
          </Text>
        </div>
      )}
    </div>
  );
};

export default CalendarList;
