import React, { lazy, Suspense } from "react";

const BaseIcon = lazy(() => import("@base/Icon"));

const Icon = props => {
  return (
    <Suspense>
      <BaseIcon {...props} />
    </Suspense>
  );
};

export default Icon;
