/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { Icon } from "@atoms";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  options,
  className: _className,
}) => {
  const [form, setForm] = formState;

  useEffect(() => {
    setForm(f => ({ ...f, [name]: options[0].value }));
  }, []);

  return (
    <div className="relative w-full">
      <select
        name={name}
        id={name}
        className={clsx(
          "relative w-full max-w-full appearance-none rounded-none border-3 border-black bg-transparent p-2 font-serif",
          _className,
          {
            "!border-red": hasError,
          }
        )}
        onChange={e => {
          setForm({ ...form, [name]: e.target.value });
        }}
      >
        <>
          {placeholder && (
            <option key="placeholder" value="">
              {placeholder}
            </option>
          )}
          {options?.map((option, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <option key={i} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </>
      </select>
      <Icon
        name="triangle"
        className="icon pointer-events-none absolute right-4 top-3 h-3 w-3 rotate-0 transform duration-100"
      />
    </div>
  );
};

InputText.defaultProps = {};

export default InputText;
