import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query NewsletterQuery {
    craft {
      globalSet(handle: "newsletter") {
        ... on Craft_newsletter_GlobalSet {
          heroHeading
          descriptor: descriptor0
          sideLabel: label0
          button: link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  return globalSet;
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
