import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AR2023HeaderQuery {
    craft {
      entries(section: "page", site: "annualReport2023") {
        url
        title
        uid
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const resolveLink = link => {
    return {
      uid: link.uid,
      text: link.title,
      url: link.url,
    };
  };

  const nav = entries.map(resolveLink);
  // const sideNav = linkList1.map(resolveLink);
  // const subNav = linkList2.map(resolveLink);

  return {
    nav,
    // sideNav,
    // subNav,
  };
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
