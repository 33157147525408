import React from "react";
import { Text } from "@atoms";

const CbLead = ({ copy }) => {
  return (
    <section className="my-10">
      <Text variant="body" className="font-semibold" richText>
        {copy}
      </Text>
    </section>
  );
};

export default CbLead;
