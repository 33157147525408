import React from "react";
import { Text, Button, ButtonAlt } from "@atoms";

const CbButton = ({ link, style }) => {
  return (
    <div className="my-10">
      {link?.url &&
        (style ? (
          <Button to={link?.url}>
            <Text variant="button">{link?.text}</Text>
          </Button>
        ) : (
          <ButtonAlt to={link?.url}>{link?.text}</ButtonAlt>
        ))}
    </div>
  );
};

export default CbButton;
