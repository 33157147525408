import React from "react";

// eslint-disable-next-line import/no-cycle
import { AppLink } from "@base";
import clsx from "clsx";
import Icon from "./Icon";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  icon,
  faux,
  className: _className,
}) => {
  const defaultClassName = `group inline-flex gap-4 justify-center items-center cursor-pointer relative z-0 font-bold group leading-tighter transition duration-300 ease-in-out`;

  const sizes = {
    lg: "px-10 py-5 text-base",
    md: "px-6 py-3.5 text-base",
    sm: "py-3 px-6 text-sm",
    xs: "px-5 py-2 text-xxs",
    "2xs": "px-3 py-0.5 text-xxs",
    anchor: "py-3.5 text-base",
  };

  const colors = {
    black: "bg-black hover:bg-black/75 text-white",
    red: "bg-red hover:bg-red-dark text-black",
    gold: "bg-gold hover:bg-gold/50 text-black",
    burgundy:
      "bg-burgundy hover:bg-black group-hover:bg-black text-white hover:text-gold group-hover:text-gold focus:bg-black group-focus:bg-black focus:text-gold group-focus:text-gold",
    transparent:
      "bg-transparent hover:bg-gold group-hover:bg-gold border-3 border-gold focus:bg-gold group-focus:focus:bg-gold",
    transparentGold:
      "bg-transparent text-gold hover:bg-gold group-hover:bg-gold group-focus:bg-gold border-3 border-gold focus:bg-gold hover:text-white group-hover:text-white focus:text-white group-focus:focus:text-white",
    transparentBlack:
      "bg-transparent text-black hover:bg-black group-hover:bg-black group-focus:bg-black border-3 border-black focus:bg-black hover:text-white group-hover:text-white focus:text-white group-focus:focus:text-white",
    anchor: "bg-transparent",
  };

  const classes = clsx(
    defaultClassName,
    "font-sans uppercase tracking-0.5",
    sizes[size] || "py-4 px-10 text-sm",
    colors[color] || color,
    _className,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  const Tag = !faux ? "button" : "span";
  const downwardIcon = icon === "download" || icon === "circledArrow";

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        <span className="relative z-10 flex w-max text-center">{children}</span>
        {icon && (
          <Icon
            name={icon}
            className={clsx("transition-transform", {
              "w-4": icon !== "circledArrow",
              "w-8": icon === "circledArrow",
              "group-hover:translate-x-1 group-focus:translate-x-1":
                !downwardIcon,
              "group-hover:translate-y-1 group-focus:translate-y-1":
                downwardIcon,
              "text-burgundy hover:text-black": icon === "circledArrow",
            })}
          />
        )}
      </LinkObj>
    );
  }
  return (
    <Tag
      // eslint-disable-next-line react/button-has-type
      type={!faux ? type : null}
      className={classes}
      onClick={onClick}
    >
      <span className="relative z-10 flex w-max text-center">{children}</span>
      {icon && (
        <Icon
          name={icon}
          className={clsx("transition-transform", {
            "w-4": icon !== "circledArrow",
            "w-8": icon === "circledArrow",
            "group-hover:translate-x-1 group-focus:translate-x-1":
              !downwardIcon,
            "group-hover:translate-y-1 group-focus:translate-y-1": downwardIcon,
            "text-burgundy hover:text-black": icon === "circledArrow",
          })}
        />
      )}
    </Tag>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "burgundy",
  title: null,
  type: "button",
  onClick: null,
  size: "md",
  to: null,
  rounded: true,
};

export default Button;
