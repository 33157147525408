import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import LZString from "lz-string";

const query = graphql`
  query AllEmail {
    allEmail {
      nodes {
        id
        list
        html
        template
      }
    }
  }
`;

const dataResolver = ({ nodes }) =>
  nodes.map(n => ({
    ...n,
    html: LZString.decompressFromUTF16(n.html),
  }));

const useData = () => {
  const { allEmail } = useStaticQuery(query);
  const data = useMemo(() => dataResolver(allEmail), []);
  return data;
};

export default useData;
