import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          social {
            ... on Craft_social_account_BlockType {
              platform
              accounturl
            }
          }
          copyright: descriptor0
          privacyLink: link0 {
            url
            text
          }
          cookieLink: link1 {
            url
            text
          }
          footerLinks {
            ... on Craft_footerLinks_columnedLinks_BlockType {
              column {
                ... on Craft_column_BlockType {
                  uid
                  linkObject {
                    text
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const {
    footerLinks,
    copyright,
    privacyLink,
    cookieLink,
    social: socials,
  } = globalSet;

  const nav = footerLinks.map(col => {
    return col.column?.map(link => {
      return {
        uid: link.uid,
        text: link.linkObject.text,
        url: link.linkObject.url,
      };
    });
  });

  return {
    nav,
    copyright,
    privacyLink,
    cookieLink,
    socials,
  };
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
