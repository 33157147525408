import React from "react";

const Marker = ({ markerSymbol }) => {
  const symbols =
    {
      restaurant: "dining",
      tenants: "office",
      sportsVenue: "stadium",
      personalCare: "selfcare",
      art: "arts",
    }[markerSymbol] ||
    markerSymbol ||
    "office";

  return (
    <img
      alt={`${markerSymbol} marker`}
      src={`/png/markers/${symbols}.png`}
      className="w-6 cursor-pointer"
      loading="eager"
    />
  );
};

export default Marker;
