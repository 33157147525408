import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import clsx from "clsx";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";

// from tailwind config
const screens = {
  xxs: { max: "350px" }, // for super small screens
  sm: "700px", // bigger than most phones
  md: "850px",
  lg: "1200px",
  xl: "1600px", // larger than 15" macbook pro
  "2xl": "2000px",
};

const Carousel = ({
  children,
  indicators,
  maxVisible,
  className: _className,
  hideArrows,
  slideState,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentSlide, setCurrentSlide] = slideState || useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    const { x: velocity } = info.velocity;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount || velocity < -400) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount || velocity > 400) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 1,
        lg: 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <div
      ref={carouselContainer}
      className={clsx("relative w-full", _className)}
    >
      {/* prev button */}
      {!hideArrows && slideCount > 1 && (
        <div className="translate-y-50 absolute left-0 top-0 bottom-0 z-10 transform items-center justify-center flex">
          <button
            className={clsx(
              "group flex items-center justify-center bg-gold p-2 md:p-4 text-black rounded-full transition duration-100",
              {
                "opacity-20": currentSlide <= 0,
              }
            )}
            type="button"
            onClick={() => {
              setCurrentSlide(prevState => {
                if (prevState > 0) {
                  return prevState - 1;
                }
                return prevState;
              });
            }}
            aria-label="Go to the previous slide"
          >
            <Icon
              name="arrow"
              className="relative right-0 h-4 w-4 rotate-180 transform transition duration-100 group-hover:-translate-x-1.5"
            />
          </button>
        </div>
      )}
      <m.div
        animate={carouselControls}
        className="flex"
        transition={{ duration: 0.5, type: "tween" }}
        style={{ width: `${slideCount * 100}%` }}
        drag={slideCount > 1 ? "x" : false}
        onDragEnd={handleDrag}
        dragConstraints={{ left: "-100%", right: 0 }}
        dragDirectionLock
      >
        {slides.map((slide, i) => (
          <div
            key={`key--${i + 1}`}
            className={clsx("relative duration-100", {
              "": i < currentSlide || i + 1 > currentSlide + visibleSlides,
            })}
            style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
          >
            {slide}
          </div>
        ))}
      </m.div>
      {/* next button */}
      {!hideArrows && slideCount > 1 && (
        <div className="absolute right-0 top-0 bottom-0 z-10 items-center justify-center flex">
          <button
            className={clsx(
              "group flex items-center justify-center bg-gold rounded-full p-2 md:p-4 text-black transition duration-100",
              {
                "opacity-20": currentSlide >= slideCount - visibleSlides,
              }
            )}
            type="button"
            onClick={() => {
              setCurrentSlide(prevState => {
                if (prevState < slideCount - visibleSlides) {
                  return prevState + 1;
                }
                return prevState;
              });
            }}
            aria-label="Go to the next slide"
          >
            <Icon
              name="arrow"
              className="relative right-0 h-4 w-4 transition duration-100 group-hover:translate-x-1.5"
            />
          </button>
        </div>
      )}
      {/* indicators */}
      {indicators && slideCount > 1 && (
        <ul className="mt-6 flex items-center justify-center">
          {slides.map((slide, i) => (
            <li key={`indicator---${i + 1}`}>
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={clsx(
                  "mx-1 block h-2 w-2 rounded-full bg-gold transition duration-100",
                  {
                    "opacity-50": currentSlide !== i,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
};

export default Carousel;
