import { useStaticQuery, graphql } from "gatsby";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query AppCtaQuery {
    craft {
      globalSet(handle: "appCta") {
        ... on Craft_appCta_GlobalSet {
          image: image0 {
            ...ImageFragment
          }
          heading: heading0
          descriptor: descriptor0
          button: link0 {
            text
            url
          }
          wordmark: heading1
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { image, heading, descriptor, button, wordmark } = globalSet;
  return {
    image: resolveImage(image),
    heading,
    descriptor,
    button,
    wordmark,
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
