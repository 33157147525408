import React, { useEffect, Suspense } from "react";

import { PageSizeObserver } from "@base";
import { LazyMotion } from "framer-motion";
import Header from "@organisms/Header";
import Footer from "@organisms/Footer";
import Modal from "@organisms/Modal";

import BrowserWarning from "../atoms/BrowserWarning";

const GdprBanner = React.lazy(() => import("@organisms/GdprBanner"));
const Userback = process.env.GATSBY_USERBACK_TOKEN
  ? React.lazy(() => import("@organisms/Userback"))
  : null;

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, path }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div className="flex min-h-screen flex-col">
        <PageSizeObserver />
        <Header />
        <Suspense>
          <>
            {children}
            {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
            <GdprBanner />
          </>
        </Suspense>
        <Footer />
        <BrowserWarning />
        <Modal />

        {/* uncomment if embedding facebook posts */}
        {/* <div id="fb-root" /> */}
      </div>
      {/* line */}
      <div className="pointer-events-none fixed inset-0 z-[80] mx-auto hidden h-full max-w-7xl px-2 sm:px-4 lg:block lg:px-10 xl:max-w-8xl xl:px-16">
        <div className="h-full w-full border-l-2 border-gold" />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
