import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { m, useAnimation } from "framer-motion";
import Icon from "./Icon";

// todo: add documentation

const Dropdown = ({
  model,
  options,
  placeholder = "",
  inverse,
  size = "md",
  sans,
  id = "dropdown",
  label,
  floatingArrow,
  transparent,
}) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = model || useState();
  const dropdown = useRef();
  const dropdownControl = useAnimation();

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    setIsActive(false);
  }, [value]);

  // animations
  useEffect(() => {
    if (isActive) {
      dropdownControl.start({
        height: "auto",
        opacity: 1,
        display: "block",
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
      });
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    if (!isActive) {
      dropdownControl.start({
        height: 0,
        opacity: 0,
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
        transitionEnd: {
          display: "hidden",
        },
      });
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let textSize;
  let iconSize;

  switch (size) {
    case "xs":
      textSize = "text-xs";
      iconSize = "w-1 h-1";
      break;
    case "sm":
      textSize = "text-sm";
      iconSize = "w-2 h-2";
      break;
    case "md":
      textSize = "text-md";
      iconSize = "w-2 h-2";
      break;
    case "lg":
      textSize = "text-lg";
      iconSize = "w-4 h-4";
      break;
    default:
      textSize = "text-md";
      iconSize = "w-3 h-3";
      break;
  }

  return (
    <div
      className={clsx("relative w-full", {
        "font-sans text-xs font-bold uppercase": sans,
        "font-serif": !sans,
      })}
    >
      <m.button
        type="button"
        ref={dropdown}
        onTap={toggleActive}
        className={clsx(
          "relative flex w-full items-center justify-between bg-transparent",
          {
            "py-1 px-3": size === "xs",
            "py-2 px-4": size !== "xs",
            "border-gold text-gold": inverse,
            "border-black text-black": !inverse,
            "overflow-hidden text-ellipsis": floatingArrow,
            "border-3": !transparent,
          },
          textSize
        )}
        aria-expanded={isActive}
        aria-controls={id}
        aria-haspopup="listbox"
      >
        <span className="mr-2 whitespace-nowrap text-left">
          {value != null && value !== ""
            ? options?.[value]?.title
            : placeholder}
        </span>
        {/* dropdown Icon */}
        <Icon
          name="triangle"
          className={clsx(
            "icon duration-100 text-inherit",
            {
              "rotate-180": isActive,
              "rotate-0": !isActive,
              "absolute right-4": floatingArrow,
            },
            iconSize
          )}
        />
      </m.button>
      <div className="relative w-full">
        <m.ul
          animate={dropdownControl}
          initial={{ height: 0, opacity: 0 }}
          className={clsx(
            "absolute left-0 right-0 top-0 z-40 h-0 overflow-hidden border-t-0 border-3",
            {
              "border-gold bg-black text-gold": inverse,
              "border-black bg-white text-black": !inverse,
              "shadow-xl": isActive,
              "shadow-none": !isActive,
              "w-max": !floatingArrow,
              "w-full": floatingArrow,
            },
            textSize
          )}
          id={id}
          role="listbox"
          aria-label={label || placeholder || `dropdown--${id}`}
          tabIndex="-1"
        >
          {placeholder.length > 1 && (
            <m.li
              role="option"
              aria-selected={false}
              className={clsx(
                "w-full cursor-pointer px-4 py-2 text-left opacity-50",
                textSize
              )}
              disabled
            >
              {placeholder}
            </m.li>
          )}
          {options?.map((option, i) => {
            return (
              <m.li
                role="option"
                aria-selected={value === option.uid}
                key={option.uid || option.value}
                onTapStart={() => setValue(i)}
                className={clsx(
                  "w-full cursor-pointer px-4 py-2 text-left transition duration-100",
                  {
                    "hover:bg-black hover:text-gold focus:bg-black focus:text-gold":
                      !inverse,
                    "hover:bg-gold hover:text-black focus:bg-gold focus:text-black":
                      inverse,
                  }
                )}
                tabIndex={isActive ? "0" : "-1"}
              >
                {option.title}
              </m.li>
            );
          })}
        </m.ul>
      </div>
    </div>
  );
};

export default Dropdown;
