const reducer = (state, action) => {
  switch (action.type) {
    case "changeColor":
      return {
        ...state,
        color: action.color,
      };
    case "clearColor":
      return {
        ...state,
        color: null,
      };
    default:
      return state;
  }
};

const initialState = {
  color: null,
};

const layout = {
  reducer,
  initialState,
};

export default layout;
