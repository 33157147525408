import React, { useState } from "react";
import clsx from "clsx";
import { Button, Text } from "@atoms";

const FormContainer = ({
  children,
  onSubmit, // ! onSubmit function must return object
  buttonText,
  className: _className,
  successMessage,
  errorMessage,
  formState,
}) => {
  // Form States
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formError, setFormError] = useState([]);

  const requiredFields = React.Children.toArray(children)
    ?.map(_f => _f.props)
    ?.map(_f => {
      if (Array.isArray(_f?.children)) {
        return _f?.children?.map(_c => _c?.props);
      }

      return _f;
    })
    ?.flat()
    ?.filter(_f => _f?.required)
    ?.map(_f => _f?.name)
    ?.sort();

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
      });
    }
    return child;
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const formKeys = Object.keys(form)
      ?.filter(_f => {
        const isEmpty =
          form[_f]?.length === 0 || form[_f] === false || form[_f] === null;
        return !isEmpty ? _f : false;
      })
      ?.sort();
    const hasRequiredFields =
      formKeys?.length > 0 &&
      requiredFields.every((value, index) => {
        return formKeys?.includes(value);
      });

    if (hasRequiredFields && !success) {
      setFormError([]);

      if (onSubmit) {
        try {
          const res = await onSubmit(e);

          if (!res.error) {
            setError(null);
            setSuccess(successMessage);
          } else {
            setSuccess(null);
            setError(
              res.error ||
                "Oops, Something happened with the form submission. Try again later."
            );
          }
        } catch (_e) {
          setError(errorMessage);
        }
      }
    } else {
      const missingFields = requiredFields?.filter(x => !formKeys.includes(x));
      // ?.concat(formKeys.filter(x => !requiredFields.includes(x)));

      setFormError(missingFields);
      setError(errorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={clsx(_className)}>
      {error && (
        <Text variant="body" className="text-red">
          {error}
        </Text>
      )}
      {success && (
        <Text variant="body" className="">
          {success}
        </Text>
      )}
      {!success && (
        <>
          {childrenWithProps}
          <div>
            <Button type="submit" icon="arrow">
              {buttonText}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

FormContainer.defaultProps = {
  buttonText: "Submit",
  successMessage: "The form has been submitted.",
  errorMessage: "Please fill out all required fields",
};

export default FormContainer;
