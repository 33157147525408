/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import clsx from "clsx";
import { m, AnimatePresence } from "framer-motion";
import { useAppState } from "@state";
import useAppCtaData from "@staticQueries/AppCtaQuery";
import Newsletter from "@organisms/Newsletter";
import AppCta from "@organisms/AppCta";

const PageContainer = ({
  children,
  className,
  path,
  flex,
  lightNewsletter,
  offwhite,
  hideNewsletter,
  showAppCta,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();
  const appCta = useAppCtaData();

  const variants = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <m.div
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      }}
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className={clsx(className, "flex min-h-screen flex-grow flex-col")}
    >
      <AnimatePresence initial>
        <article
          key="content"
          className={clsx("flex-grow", {
            "flex flex-col items-center justify-center": flex,
            "bg-offwhite": offwhite,
          })}
        >
          {children}
        </article>
        {!hideNewsletter && (
          <Newsletter
            key="newsletter"
            wordmark="Newsletter"
            light={lightNewsletter}
          />
        )}
        {showAppCta && <AppCta key="cta" {...appCta} />}
      </AnimatePresence>
    </m.div>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
