import React from "react";
import clsx from "clsx";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  id,
  className: _className,
  autocomplete,
  color,
}) => {
  const [form, setForm] = formState;

  return (
    <input
      type="text"
      name={name}
      id={id || name}
      placeholder={placeholder}
      autoComplete={autocomplete}
      className={clsx(
        "text-body w-full max-w-full rounded-none  border-b-2 bg-transparent p-0.5 pb-1 font-serif",
        _className,
        {
          "!border-red": hasError,
          "border-gold text-gold": color === "gold",
          "border-black": color !== "gold",
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

InputText.defaultProps = {};

export default InputText;
