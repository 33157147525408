import React from "react";
import { Text, Container, Button } from "@atoms";
import useNewsletterData from "@staticQueries/NewsletterQuery";
import { HeadingOutlined, FormNewsletter, Form } from "@molecules";
import clsx from "clsx";
import NewsletterSignup from "@organisms/NewsletterSignup";

const Newsletter = ({
  light,
  heading,
  copy,
  wordmark,
  url,
  formHeight,
  email,
  form: _form,
  hideButton,
}) => {
  const { heroHeading, descriptor, button } = useNewsletterData();
  return (
    <section
      id="signup"
      className={clsx("overflow-hidden bg-gold", {
        "sm:bg-white": light,
        "sm:bg-offwhite": !light,
      })}
    >
      <Container label wordmark={wordmark} centerWordmark>
        <div className="flex flex-col items-center md:flex-row">
          {/* Content Side */}
          <div className="w-full py-10 pl-4 pr-0 sm:py-20 sm:pl-6 sm:pr-5 md:w-1/2 md:pl-8 lg:pl-10 xl:pl-16">
            <div className="ml-auto max-w-none md:max-w-lg xl:max-w-[34rem]">
              <HeadingOutlined text={heading || heroHeading} />
              <Text variant="body" className="mt-5 max-w-md" richText>
                {copy || descriptor}
              </Text>
              {button?.url && !hideButton && (
                <div className="mt-5">
                  <Button to={button.url} icon="plus">
                    {button.text}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* Form */}
          <div className="relative bg-gold w-full px-4 sm:px-6 md:w-1/2 md:px-10 lg:px-12 xl:px-16">
            <div className="relative z-10 flex w-full items-center py-10 sm:py-20 md:max-w-lg md:justify-center">
              {/* email forms */}
              {email && !url && <Form form={_form} />}
              {/* old newsletters */}
              {!email && url && (
                <FormNewsletter
                  title={heading || heroHeading}
                  url={url}
                  formHeight={formHeight}
                />
              )}
              {/* default newsletter signup */}
              {!email && !url && <NewsletterSignup small />}
            </div>
            <div className="absolute inset-0 left-0 z-0 hidden h-full w-screen bg-gold md:block" />
          </div>
        </div>
      </Container>
    </section>
  );
};

Newsletter.defaultProps = {};

export default Newsletter;
