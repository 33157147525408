import React from "react";
import { Text, Icon } from "@atoms";

const CbQuote = ({ quote, citation }) => {
  return (
    <div className="relative my-10 space-y-3 bg-burgundy p-8 pt-10 text-white">
      {/* quote */}
      <div className="absolute -top-4 left-4 flex h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-gold">
        <Icon name="quote" className="w-6" />
      </div>
      <Text variant="blockquote">{quote}</Text>
      <Text variant="label">{citation}</Text>
    </div>
  );
};

export default CbQuote;
