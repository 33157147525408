import React from "react";
import clsx from "clsx";
import { Text } from "@atoms";

const HeadingOutlined = ({ text, color, variant }) => {
  const colors = {
    black: "text-stroke text-black",
    gold: "text-stroke text-gold",
    lightGold: "text-stroke text-white gold-highlight",
    darkGold: "text-stroke text-black gold-highlight",
  };
  return (
    <Text variant={variant} className={clsx(colors[color])}>
      {text}
    </Text>
  );
};

HeadingOutlined.defaultProps = {
  color: "black",
  variant: "h3",
};

export default HeadingOutlined;
