import React from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  icon,
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={clsx(
        "inline-flex items-center gap-3 uppercase transition duration-100 hover:opacity-50",
        _className
      )}
    >
      {/* back arrow */}
      {icon && reverse && (
        <Icon
          name={icon || "chevron"}
          className={clsx("flex-shink-0 ml-1 h-4 w-2.5", {
            "rotate-180": icon === "chevron" && reverse,
          })}
        />
      )}
      {/* label */}
      <span className="text-body font-bold leading-normal">{children}</span>
      {/* forward arrow */}
      {icon && !reverse && (
        <Icon name={icon || "chevron"} className="flex-shink-0 ml-1 h-4 w-4" />
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  icon: false,
};

export default ButtonAlt;
