import React from "react";
import { Text, Button } from "@atoms";

const Popup = ({ title, description, address }) => {
  const directions = `https://www.google.com/maps/dir//${encodeURI(address)}`;

  return (
    <div className="space-y-3">
      <Text variant="h6">{title}</Text>
      {description && <Text variant="xs">{description}</Text>}
      {address && (
        <>
          <Text variant="xs">{address}</Text>
          <Button to={directions} size="xs">
            Directions
          </Button>
        </>
      )}
    </div>
  );
};

export default Popup;
