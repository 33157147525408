import React, { useRef, useEffect } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import { useAppState } from "@state";
import { Icon } from "@atoms";
import toggleBodyScroll from "@utils/toggleBodyScroll";

const Modal = () => {
  const modal = useRef();
  const [{ modal: modalState }, dispatch] = useAppState();
  const {
    modal: open,
    content: Content,
    background,
    title,
    description,
    focusId,
  } = modalState;

  const transition = {
    type: "tween",
    duration: 0.2,
  };

  const containerVariants = {
    open: {
      pointerEvents: "auto",
      opacity: 1,
      transition,
    },
    closed: {
      pointerEvents: "none",
      opacity: 0,
      transition,
    },
  };

  const contentVariants = {
    open: {
      opacity: 1,
      transition: {
        ...transition,
        type: "tween",
        tween: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      transition: {
        ...transition,
        type: "tween",
        tween: "easeOut",
      },
    },
  };

  useEffect(() => {
    const handleKey = e => {
      if (parseInt(e.keyCode, 10) === 27) {
        dispatch({ type: "closeModal" });
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      toggleBodyScroll(true, modal.current);
      dispatch({ type: "closeModal" });
    }
  }, [open]);

  return (
    <m.div
      animate={open ? "open" : "closed"}
      initial="closed"
      variants={containerVariants}
      className={clsx(
        "fixed inset-0 z-[100] bg-opacity-[0.975] md:px-[64px] md:pt-[64px]",
        background,
        {
          "pointer-events-none": !open,
        }
      )}
      onAnimationComplete={(a, b) => {
        if (!open && modal.content) {
          dispatch({ type: "clearModalContent" });
        } else if (open) {
          if (focusId) {
            document.getElementById(focusId).focus();
          }

          toggleBodyScroll(false, modal.current);
        }
      }}
    >
      <button
        type="button"
        className={clsx(
          "absolute inset-0 z-0 flex w-full items-center text-xs font-bold uppercase text-transparent",
          {}
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <span className="sr-only">Close</span>
      </button>
      <button
        type="button"
        className={clsx(
          "absolute top-0 right-0 z-30 flex items-center p-3 text-xs font-bold uppercase text-white"
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <span className="sr-only">close</span>
        <Icon name="close" className="h-6 w-6" />
      </button>
      <m.div
        ref={modal}
        animate={open ? "open" : "closed"}
        className="relative z-30 h-full"
        variants={contentVariants}
        aria-labelledby={title ? "modalTitle" : null}
        aria-describedby={description ? "modalDescription" : null}
      >
        {!!Content && (
          <>
            {title && (
              <h2 id="modalTitle" className="sr-only">
                {title}
              </h2>
            )}
            {description && (
              <p id="modalDescription" className="sr-only">
                {description}
              </p>
            )}
            <Content />
          </>
        )}
      </m.div>
    </m.div>
  );
};

export default Modal;
