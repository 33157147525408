import React, { useState } from "react";
import { Text, Container, Icon } from "@atoms";
import { AppLink } from "@base";
import { MobileHeader, Logo, HeaderSearch } from "@molecules";
import { useAppState } from "@state";
import clsx from "clsx";
import { m } from "framer-motion";
import useHeaderData from "@staticQueries/HeaderQuery";
import useAR2023HeaderData from "@staticQueries/AR2023HeaderQuery";

const Header = () => {
  const [{ layout }, dispatch] = useAppState();
  const [searchVisible, setSearchVisible] = useState(false);

  const site = process.env.GATSBY_CRAFT_SITE_HANDLE || "default";

  // Mobile nav triggers
  const { hideNav } = layout;

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "4",
    strokeLinecap: "round",
  };

  // todo: handle future ARs
  // Placeholder to be replaced with useHeaderData
  // or other static queries
  // this variable won't change so this can be called conditionally
  const { nav, sideNav, subNav } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    site === "default" ? useHeaderData() : useAR2023HeaderData();

  return (
    <header className="sticky top-0 z-[100] flex h-16 w-full flex-col items-center bg-black">
      <Container variant="xl" className="py-3">
        <div className="flex items-center justify-between gap-10">
          <AppLink
            to="https://stanfordresearchpark.com/"
            className="group flex flex-col gap-[0.125rem] text-justify"
          >
            <Logo fitHeight className="h-10" />
          </AppLink>
          <nav
            aria-label="Header navigation"
            className="flex h-full items-center gap-12"
          >
            <ul className="hidden space-x-4 md:flex lg:space-x-7">
              {nav?.map(link => {
                const { text, url, uid } = link;
                return (
                  <li key={uid}>
                    <AppLink to={url}>
                      <Text
                        variant="buttonSm"
                        className="text-gold transition-colors duration-300 ease-in-out hover:text-white focus:text-white"
                      >
                        {text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>

            <div className="flex gap-6">
              {site === "default" && (
                <button
                  type="button"
                  aria-label="Toggle search form"
                  onClick={() => {
                    setSearchVisible(!searchVisible);
                  }}
                >
                  <Icon name="search" className="mt-1 w-4" />
                </button>
              )}

              <button
                type="button"
                className={clsx("flex", { "md:hidden": site !== "default" })}
                aria-label="Toggle side navigation"
                onClick={() => {
                  dispatch({
                    type: hideNav ? "showNav" : "hideNav",
                  });
                }}
              >
                {/* Hamburger Menu with animated SVG */}
                <span className="svg-icon w-6 text-white">
                  <svg width="34" height="34" viewBox="0 0 34 24">
                    <m.path
                      {...iconStyles}
                      variants={{
                        closed: { d: "M32 2L2 2" },
                        open: { d: "M24 22L2 2" },
                      }}
                    />
                    <m.path
                      {...iconStyles}
                      d="M32 12L2 12"
                      variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 },
                      }}
                      transition={{ duration: 0.2 }}
                    />
                    <m.path
                      {...iconStyles}
                      variants={{
                        closed: { d: "M32 22L2 22" },
                        open: { d: "M24 2L2 22" },
                      }}
                    />
                  </svg>
                </span>
              </button>
            </div>
          </nav>
        </div>
      </Container>
      <HeaderSearch visibleState={[searchVisible, setSearchVisible]} />
      <MobileHeader nav={nav} sideNav={sideNav} subNav={subNav} />
    </header>
  );
};

Header.defaultProps = {};

export default Header;
