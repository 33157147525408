import { useMemo } from "react";

const usePagination = ({ pages, currentPage, rangeLimit }) => {
  // calculate total number of pages based on the length of the data
  // and the number of components to display per page

  // helper to create an array of pages
  const range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (a, i) => i + start);
  };

  const paginationRange = useMemo(() => {
    let pageNumbers;

    // check to see if the combined ranges will exceed the
    // max number of pages
    const maxRange = rangeLimit * 2 + 1;
    if (rangeLimit && maxRange < pages - 1) {
      // determine next and previous page numbers
      const leftPageIndex = Math.max(currentPage - rangeLimit, 1);
      const rightPageIndex = Math.min(currentPage + rangeLimit, pages);

      // check for when to show "..." instead of the page number
      const showLeftDots = leftPageIndex > 1;
      const showRightDots = rightPageIndex < pages;

      // the number of pages exceeds the rangeLimit range
      // so show the right dots but not the left dots
      if (!showLeftDots && showRightDots) {
        const leftRange = range(1, maxRange);
        pageNumbers = [...leftRange, "...", pages];
      }

      // the rangeLimit range is above than the total number of pages
      // so show the left dots but not the right dots
      if (showLeftDots && !showRightDots) {
        const rightRange = range(pages + 1 - maxRange, pages);
        pageNumbers = [1, "...", ...rightRange];
      }

      // there are both pages less than and greater than the rangeLimit range
      // so show both left and right dots
      if (showLeftDots && showRightDots) {
        const middleRange = range(leftPageIndex, rightPageIndex);
        pageNumbers = [1, "...", ...middleRange, "...", pages];
      }
    } else {
      // the number of pages is within the maximum range
      // so show all page numbers
      pageNumbers = range(1, pages);
    }
    return pageNumbers;
  }, [pages, currentPage, rangeLimit]);

  return paginationRange;
};

export default usePagination;
