import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";
import clsx from "clsx";
import { Container, Button } from "@atoms";
import { m } from "framer-motion";

const HeaderSearch = ({ visibleState }) => {
  const search = useRef();
  const query = useRef();
  const [searchVisible, setSearchVisible] = visibleState;

  const handleSubmit = e => {
    e.preventDefault();
    if (query.current.value) {
      setSearchVisible(false);
      navigate(`/search?q=${query.current.value}`);
    }
  };

  // const handleClickOutside = event => {
  //   if (
  //     searchVisible &&
  //     search.current &&
  //     !search.current.contains(event.target)
  //   ) {
  //     setSearchVisible(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [searchVisible]);

  useEffect(() => {
    return () => {
      setSearchVisible(false);
    };
  }, []);

  const transition = {
    type: "tween",
    duration: 0.2,
  };

  const containerVariants = {
    open: {
      pointerEvents: "auto",
      opacity: 1,
      transition,
    },
    closed: {
      pointerEvents: "none",
      opacity: 0,
      transition,
    },
  };

  return (
    <m.div
      ref={search}
      className={clsx("w-full bg-black py-4", {
        "pointer-events-none": !searchVisible,
      })}
      animate={searchVisible ? "open" : "closed"}
      initial="closed"
      variants={containerVariants}
    >
      <Container>
        <form
          onSubmit={handleSubmit}
          className="flex w-full items-end justify-end gap-8"
        >
          <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="headerSearch"
              className="mb-2 flex font-sans text-sm font-bold uppercase leading-none tracking-0.5 text-gold"
            >
              Search:
            </label>
            <input
              id="headerSearch"
              ref={query}
              className="text-body w-full max-w-lg rounded-none border-b-2 border-gold bg-transparent p-0.5 pb-1 font-serif text-gold"
            />
          </div>
          <Button type="submit" color="transparentGold" size="sm">
            Search
          </Button>
        </form>
      </Container>
    </m.div>
  );
};

HeaderSearch.defaultProps = {};

export default HeaderSearch;
