import React from "react";
import { Text, Image, Button } from "@atoms";

const PropertyFeatured = ({ title, excerpt, description, image, url }) => {
  return (
    <div className="flex flex-col gap-8 sm:flex-row md:gap-16">
      {/* main details */}
      <div className="flex w-full flex-col gap-8 md:flex-row">
        {/* image */}
        <div className="w-full max-w-lg overflow-hidden rounded-lg">
          <Image image={{ ...image, width: 488, height: 288 }} />
        </div>

        <div className="md:max-w-[14rem]">
          <Text variant="h4" className="mb-6 uppercase text-white">
            {title}
          </Text>
          {/* exceprt */}
          <Text variant="body" className="text-white">
            {excerpt}
          </Text>
        </div>
      </div>

      {/* things */}
      <div className="item flex flex-col items-start justify-between gap-8 border-t border-gold pt-8 sm:max-w-[14rem] sm:border-t-0 sm:border-l sm:pt-0 sm:pl-8 md:pl-12">
        <Text variant="serifDescSm" className="text-white">
          {description}
        </Text>

        {/* button */}
        <Button to={url} color="transparentGold" icon="arrow" size="sm">
          View Property
        </Button>
      </div>
    </div>
  );
};

PropertyFeatured.defaultProps = {};

export default PropertyFeatured;
