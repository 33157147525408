import React from "react";
import { Text, Image } from "@atoms";

const CarouseSlide = ({ heading, copy, image }) => {
  return (
    <div className="flex flex-col lg:flex-row sm:max-w-4xl lg:max-w-3xl gap-8 lg:gap-12 items-center mx-auto px-12 lg:px-10">
      <div className="w-44 aspect-square sm:w-80 relative overflow-hidden rounded-full shrink-0">
        <Image image={image} fill />
      </div>

      <div className="space-y-4">
        <Text variant="h4">{heading}</Text>
        <Text
          variant="body"
          richText
          className="text-white text-base sm:text-xl leading-snug"
        >
          {copy}
        </Text>
      </div>
    </div>
  );
};

export default CarouseSlide;
