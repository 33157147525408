import React from "react";
import { Text, Image } from "@atoms";

const CbImage = ({ images, caption }) => {
  return (
    <figure className="my-10">
      <div className="flex flex-wrap gap-2 sm:flex-nowrap">
        {images?.map(image => (
          <Image image={image} preserveAspectRatio />
        ))}
      </div>
      {caption && (
        <Text variant="caption" className="mt-2">
          {caption}
        </Text>
      )}
    </figure>
  );
};

CbImage.defaultProps = {};

export default CbImage;
