import React from "react";
import { AppLink } from "@base";
import { Text, Image } from "@atoms";

const CardArticle = ({ title, url, byline, image, description }) => {
  return (
    <AppLink to={url} className="group flex items-start justify-start gap-4">
      {image && (
        <div className="relative aspect-square w-full max-w-[4rem] shrink-0 overflow-hidden rounded-lg  bg-black sm:max-w-[7rem]">
          <Image
            image={image}
            fill
            className="ease-in-out group-hover:opacity-40 group-focus:opacity-40"
          />
        </div>
      )}
      <div className="flex flex-col gap-2 sm:gap-4">
        <Text variant="h6">{title}</Text>
        {byline && <Text className="text-xs">{byline}</Text>}
        {description && (
          <Text variant="sm" className="line-clamp-2">
            {description}
          </Text>
        )}
        <Text
          variant="buttonSm"
          className="underline-black underline decoration-2 underline-offset-4 transition-opacity group-hover:opacity-60 group-focus:opacity-60"
        >
          Read more
        </Text>
      </div>
    </AppLink>
  );
};

CardArticle.defaultProps = {};

export default CardArticle;
