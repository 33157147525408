import React, { useRef, useEffect } from "react";
import { Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
import clsx from "clsx";
import { m, AnimatePresence } from "framer-motion";
import toggleBodyScroll from "@utils/toggleBodyScroll";
import Logo from "./Logo";
import Socials from "./Socials";

const MobileHeader = ({ nav, sideNav, subNav }) => {
  const [{ layout }, dispatch] = useAppState();
  const navRef = useRef(null);
  // Mobile nav triggers
  const { hideNav } = layout;

  useEffect(() => {
    if (navRef.current) toggleBodyScroll(hideNav, navRef.current);
  }, [hideNav]);

  if (nav) {
    return (
      <div
        aria-label="Sidebar navigation"
        aria-live={!hideNav ? "polite" : "off"}
      >
        <AnimatePresence>
          {!hideNav && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                key="navScrim"
                className={clsx(
                  "fixed inset-0 z-[110] h-full w-full cursor-zoom-out bg-black transition-opacity duration-300 ease-in-out",
                  {
                    "pointer-events-none bg-opacity-0": hideNav,
                    "bg-opacity-60": !hideNav,
                  }
                )}
                onClick={() => {
                  dispatch({
                    type: "hideNav",
                  });
                }}
              />
              <m.div
                key="navLinks"
                className={clsx(
                  "fixed top-0 right-0 z-[120] h-full w-full bg-burgundy px-6 py-6 sm:max-w-xs ",
                  {
                    "pointer-events-none": hideNav,
                  }
                )}
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ ease: "easeInOut", duration: 0.3 }}
              >
                <AppLink to="/" className="group inline-block">
                  <Logo fitHeight className="h-10" />
                </AppLink>

                <nav
                  className="mt-7 flex max-h-[calc(100%-5rem)] flex-col gap-4 overflow-auto sm:gap-6 lg:max-h-max lg:overflow-visible"
                  ref={navRef}
                >
                  <ul className="space-y-4 sm:space-y-6">
                    {nav?.map((link, i) => {
                      const { text, url } = link;

                      return (
                        <li key={link.uid} className="block md:hidden">
                          <AppLink
                            to={url}
                            className="font-bold text-white transition-colors duration-300 ease-in-out hover:text-gold focus:text-gold"
                          >
                            <Text variant="body">{text}</Text>
                          </AppLink>
                        </li>
                      );
                    })}
                    {sideNav?.map((link, i) => {
                      const { text, url } = link;

                      return (
                        <li key={link.uid}>
                          <AppLink
                            to={url}
                            className="font-bold text-white transition-colors hover:text-gold focus:text-gold"
                          >
                            <Text variant="body">{text}</Text>
                          </AppLink>
                        </li>
                      );
                    })}
                  </ul>

                  {subNav && (
                    <ul className="max-w-[10rem] space-y-3 border-y border-gold py-4">
                      {subNav?.map((link, i) => {
                        const { text, url } = link;

                        return (
                          <li key={link.uid}>
                            <AppLink
                              to={url}
                              className="text-white transition-colors hover:text-gold focus:text-gold"
                            >
                              <Text variant="xs">{text}</Text>
                            </AppLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div>
                    <Button
                      to="https://stanfordresearchpark.com/app/"
                      color="gold"
                      size="xs"
                    >
                      Download App
                    </Button>
                  </div>
                  <ul>
                    <Socials />
                  </ul>
                </nav>
                <button
                  type="button"
                  className="absolute top-5 right-6 text-white transition-colors hover:text-gold focus:text-gold sm:top-4 sm:right-10"
                  aria-label="Close side menu"
                  onClick={() => {
                    dispatch({
                      type: "hideNav",
                    });
                  }}
                >
                  <Icon name="close" className="w-6 text-current sm:w-8" />
                </button>
              </m.div>
            </>
          )}
        </AnimatePresence>
      </div>
    );
  }
  return null;
};

MobileHeader.defaultProps = {};

export default MobileHeader;
