import React from "react";
import { AppLink } from "@base";
import { Icon } from "@atoms";
import useFooterData from "@staticQueries/FooterQuery";

const Socials = () => {
  const { socials } = useFooterData();

  return (
    <li className="flex items-center gap-2">
      {socials?.map((s, i) => {
        const { platform, accounturl } = s;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <AppLink
            to={accounturl}
            key={platform}
            className="flex h-5 w-5 items-center justify-center rounded-full bg-gold text-black transition-colors hover:bg-white"
          >
            <Icon
              name={platform}
              className="h-2.5 w-2.5 text-current"
              fitHeight={platform === "facebook"}
            />
          </AppLink>
        );
      })}
    </li>
  );
};

Socials.defaultProps = {};

export default Socials;
