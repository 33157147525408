import React from "react";
import { Image, Text } from "@atoms";

const TeamMember = ({ fullName, jobTitle, shortBio, headshot }) => {
  return (
    <div>
      {headshot && (
        <div className="mb-8 overflow-hidden rounded-lg bg-black">
          <Image
            image={{ ...headshot, width: 364, height: 355 }}
            className="overflow-hidden rounded-lg group-hover:opacity-60 group-focus:opacity-60"
            ixParams={{ w: 364, h: 355 }}
          />
        </div>
      )}
      <Text variant="h6" className="my-8 uppercase">
        {fullName}
      </Text>
      {jobTitle && (
        <Text variant="body" className="font-bold">
          {jobTitle}
        </Text>
      )}
      {shortBio && (
        <Text variant="sm" className="my-6">
          {shortBio}
        </Text>
      )}
    </div>
  );
};

TeamMember.defaultProps = {};

export default TeamMember;
