import React, { useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import useWindowSize from "@hooks/useWindowSize";

// select a specific weekday style depending on
const getWeekdayView = width => {
  if (width >= 1200) {
    return "long";
  }
  if (width >= 800 && width < 1200) {
    return "short";
  }
  return "narrow";
};

const Cal = React.memo(({ events, calendarRef }) => {
  // get the window width for cal weekdays
  const { innerWidth } = useWindowSize();
  const weekedStyle = getWeekdayView(innerWidth);

  // have the weekday in a memo to try and cut down on re-renders
  const weekday = useMemo(() => getWeekdayView(innerWidth), [weekedStyle]);

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      weekends
      events={events}
      ref={calendarRef}
      // hiding these as custom bits are used instead
      headerToolbar={{
        start: "",
        center: "",
        end: "",
      }}
      views={{
        dayGridMonth: {
          dayHeaderFormat: {
            weekday,
          },
        },
      }}
    />
  );
});

export default Cal;
