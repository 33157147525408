import React, { useState } from "react";
import clsx from "clsx";
import useProperyData from "@staticQueries/AllPropertiesQuery";
import { Input, Text } from "@atoms";
import Card from "./Card";

const Button = ({ children, id, state }) => {
  const [active, setActive] = state;
  const buttonStyles = clsx(
    "font-sans uppercase tracking-0.5 rounded-full px-4 py-2 text-sm bg-transparent text-gold hover:bg-gold hover:text-white border-3 transition duration-300 ease-in-out",
    {
      "border-gold": active === id,
      "border-transparent": active !== id,
    }
  );

  return (
    <button
      type="button"
      className={buttonStyles}
      onClick={() => setActive(id)}
    >
      {children}
    </button>
  );
};

const PropertyList = () => {
  const properties = useProperyData();
  const [active, setActive] = useState("all");

  // square foot inputs
  const [sqFt, setSqFt] = useState({ minSq: 0, maxSq: 0 });

  const filtered = properties.filter(property => {
    const { squareFootage, officeSharing, subleasing, dateAvailable } =
      property;
    const inPast = new Date(dateAvailable) < new Date();
    // sq feet range
    const { minSq, maxSq } = sqFt;
    const min = squareFootage >= Number(minSq);
    const max = squareFootage <= (Number(maxSq) || Infinity);
    const inSqFtRange = min && max;

    if (active === "sqft" && !inSqFtRange) return null;
    if (active === "sharing" && !officeSharing) return null;
    if (active === "subleasing" && !subleasing) return null;
    if (active === "date" && inPast) return null;

    return property;
  });

  return (
    <div>
      <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
        {/* filters */}
        <div className="flex flex-wrap gap-2 sm:mb-12">
          <Button id="all" state={[active, setActive]}>
            All
          </Button>
          <Button id="sqft" state={[active, setActive]}>
            Sq Footage
          </Button>
          <Button id="sharing" state={[active, setActive]}>
            Office Sharing
          </Button>
          <Button id="subleasing" state={[active, setActive]}>
            Subleasing
          </Button>
          <Button id="date" state={[active, setActive]}>
            Available
          </Button>
        </div>

        {/* sqft inputs */}
        {active === "sqft" && (
          <div className="flex max-w-xs gap-8">
            <Input
              label="Minimum Sq Feet"
              id="minSq"
              name="minSq"
              type="number"
              formState={[sqFt, setSqFt]}
              color="gold"
              className="max-w-xs"
            />
            <Input
              label="Maximum Sq Feet"
              id="maxSq"
              name="maxSq"
              type="number"
              formState={[sqFt, setSqFt]}
              color="gold"
              className="max-w-xs"
            />
          </div>
        )}
      </div>

      {/* properties list */}
      <div className="mt-6 grid grid-cols-1 gap-14 gap-y-20 sm:grid-cols-2">
        {filtered?.map(property => (
          <Card key={property.uid} {...property} exploreButton />
        ))}
        {!filtered?.length && (
          <Text variant="sm" className="text-gold">
            There are no properties that match your filters.
          </Text>
        )}
      </div>
    </div>
  );
};

PropertyList.defaultProps = {};

export default PropertyList;
