import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query AllPropertiesQuery {
    craft {
      entries(section: "properties") {
        ... on Craft_properties_default_Entry {
          uid
          heading: title
          images {
            ...ImageFragment
          }
          excerpt: descriptor0
          description: descriptor1
          url
          # extra details
          squareFootage
          officeSharing
          subleasing
          dateAvailable
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  return entries.map(entry => {
    return {
      ...entry,
      image: resolveImage(entry.images),
    };
  });
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
