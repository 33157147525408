import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import { Image, Text, Button } from "@atoms";

const Card = ({
  title,
  heading,
  description,
  image,
  url,
  index,
  centeredText,
  button,
  exploreButton,
  detailsButton,
  start,
  end,
  wide,
  onClick,
  event,
}) => {
  const sizes = [
    [327, 170],
    [327, 379],
    [327, 235],
  ];
  const classes = [
    "aspect-[327/170]",
    "aspect-[327/170] sm:aspect-[327/379]",
    "aspect-[327/170] sm:aspect-[327/235]",
  ];
  const w = exploreButton || detailsButton ? 550 : 364;
  const h = 355;
  const date = new Date(Date.parse(start)).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const Tag = url || button?.url || onClick ? AppLink : "div";
  return (
    <Tag
      to={url || button?.url}
      className={clsx("relative flex h-full w-full flex-col", {
        "item-end text-left": !centeredText,
        "items-center text-center": centeredText,
        // "justify-between": heading,
        group: url || button?.url,
      })}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <div className="w-full shrink-0">
        {image && (
          <div className="mb-7 overflow-hidden rounded-lg bg-black">
            <Image
              image={
                title && !start
                  ? image
                  : {
                      ...image,
                      width: event ? 1600 : w,
                      height: event ? 900 : h,
                    }
              }
              className={clsx(
                title ? classes[index % 3] : "",
                "overflow-hidden rounded-lg ease-in-out group-hover:opacity-40 group-focus:opacity-40",
                { "aspect-[18/9]": event, "aspect-[8/5]": wide }
              )}
              aspectRatio={title && !start ? [...sizes[index % 3]] : [w, h]}
            />
          </div>
        )}
        <div className={clsx({ "mb-2": title, "mb-4": heading })}>
          <Text variant="h6">{title || heading}</Text>
          {start && date && (
            <span className="mt-2 block font-serif text-base leading-normal">
              {date}
            </span>
          )}
        </div>
        <Text variant="body" className="mb-6">
          {description}
        </Text>
      </div>

      {title && !start && (
        <Text
          variant="buttonSm"
          className="underline-black underline decoration-2 underline-offset-4 transition-opacity group-hover:opacity-60 group-focus:opacity-60"
        >
          Read more
        </Text>
      )}

      {(button?.url || onClick) && (
        <Button
          faux
          icon="arrow"
          className={clsx({ "self-start": !centeredText })}
        >
          {button.text}
        </Button>
      )}

      {(exploreButton || detailsButton) && (
        <Button
          faux
          icon="arrow"
          className={clsx({ "self-start": !centeredText })}
        >
          {exploreButton ? "Explore" : "Get the details"}
        </Button>
      )}
    </Tag>
  );
};

Card.defaultProps = {};

export default Card;
