import React, { useState, useEffect } from "react";
import { Input, FieldSet } from "@atoms";
import clsx from "clsx";
import FormContainer from "./FormContainer";

const Form = ({ url, formHeight, form: _form }) => {
  const [form, setForm] = useState({});
  const { fields, emailToSendTo, title } = _form;

  useEffect(() => {
    setForm(lastForm => ({ ...lastForm, emailToSendTo, title }));
  }, []);

  const onSubmit = async () => {
    const request = await fetch("/api/email", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(form),
    });
    try {
      const response = request.json();
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return error;
    }
  };
  return (
    <FormContainer
      className="flex w-full flex-col gap-4 gap-y-8"
      buttonText="Submit"
      formState={[form, setForm]}
      onSubmit={onSubmit}
      successMessage="Thank you for your submission. A member of the SRP Team will respond within 2 business days."
    >
      {fields?.map((field, i) => {
        if (Array.isArray(field)) {
          return (
            <FieldSet
              key={field?.map(input => input.handle).join("_")}
              className="flex flex-col gap-4 gap-y-8 sm:flex-row"
            >
              {field?.map((input, _i) => (
                <Input
                  key={`${input?.handle}-${_i + 1}`}
                  label={input?.label}
                  type={input?.type}
                  className="w-full"
                  id={input?.handle}
                  // gets rid of colons
                  name={input?.label.replace(/^(.*?):*$/, "$1").trim()}
                  required={input?.required}
                  options={field?.options}
                  group={field?.options}
                />
              ))}
            </FieldSet>
          );
        }
        return (
          <Input
            key={`${field?.handle}-${i + 1}`}
            label={field?.label}
            type={field?.type}
            id={field?.handle}
            // gets rid of colons
            name={field?.label.replace(/^(.*?):*$/, "$1").trim()}
            group={field?.options}
            options={field.type === "checkbox" ? null : field.options}
            required={field?.required}
            className={clsx("w-full", {
              "sm:w-1/2": field.type === "select" && i === 0,
            })}
          />
        );
      })}
    </FormContainer>
  );
};

export default Form;
