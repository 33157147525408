import React from "react";
import { AppLink } from "@base";
import { Text } from "@atoms";
import Socials from "./Socials";

const ComponentName = ({ links }) => {
  return (
    <div className="grid w-full max-w-3xl grid-cols-1 gap-px bg-gold sm:grid-cols-3 md:grid-cols-footer-4">
      {links?.map((col, i) => {
        return (
          <div
            key={`col--${i + 1}`}
            className="flex h-full w-full bg-black pb-4 last:col-span-full sm:pb-0 last:md:col-span-1"
          >
            <ul className="space-y-4 self-end px-0 py-4 sm:px-4 sm:px-10 md:pt-36">
              {col.map(link => (
                <li key={link.uid}>
                  <AppLink
                    to={link.url}
                    className="font-medium text-gold underline-offset-2 transition-colors duration-300 ease-in-out hover:text-white hover:underline focus:text-white"
                  >
                    <Text variant="sm">{link.text}</Text>
                  </AppLink>
                </li>
              ))}

              {i === links.length - 1 && <Socials />}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default ComponentName;
