import React from "react";
// import { Input, FieldSet } from "@atoms";
// import FormContainer from "./FormContainer";

const FormNewsletter = ({ title, url, formHeight }) => {
  return (
    // <FormContainer
    //   className="flex w-full flex-col gap-4 gap-y-8"
    //   buttonText="Subscribe"
    // >
    //   <FieldSet
    //     label="Full name"
    //     hideLabel
    //     className="flex flex-col gap-4 gap-y-8 sm:flex-row"
    //   >
    //     <Input
    //       label="First name:"
    //       type="text"
    //       className="w-full"
    //       autocomplete="given-name"
    //     />
    //     <Input
    //       label="Last name:"
    //       type="text"
    //       className="w-full"
    //       autocomplete="family-name"
    //     />
    //   </FieldSet>
    //   <Input label="Email Address:" type="email" autocomplete="email" />
    //   <Input label="ZIP code:" type="text" autocomplete="postal-code" />
    // </FormContainer>

    // to hopefully be replaced
    <iframe
      title={title}
      src={url}
      type="text/html"
      // eslint-disable-next-line react/no-unknown-property
      allowtransparency="true"
      style={{ border: 0, outline: "none" }}
      width="100%"
      height={formHeight}
      frameBorder="0"
    />
  );
};

export default FormNewsletter;
