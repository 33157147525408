import React from "react";
import clsx from "clsx";
import { Icon, Text, Container } from "@atoms";
import { Wrapper, AppLink } from "@base";

const Share = ({ condition = true, title, url, className: _className }) => {
  const urls = {
    LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`,
  };

  const classes = clsx(
    "relative z-10 flex h-6 w-6 items-center justify-center rounded-full p-1.5 bg-gold"
  );

  return (
    <Wrapper condition={condition} component={Container} variant="xxs">
      <div className={clsx("flex items-center gap-4", _className)}>
        {title && <Text className="font-sans">{title}</Text>}
        <AppLink to={urls.LinkedIn} target="_blank" className={classes}>
          <Icon name="linkedin" className="h-full w-full text-black" />
        </AppLink>
        <AppLink to={urls.Twitter} target="_blank" className={classes}>
          <Icon name="twitter" className="h-full w-full text-black" />
        </AppLink>
        <AppLink to={urls.Facebook} target="_blank" className={classes}>
          <Icon name="facebook" className="h-full w-2 text-black" />
        </AppLink>
      </div>
    </Wrapper>
  );
};

Share.defaultProps = {
  title: "Share:",
};

export default Share;
