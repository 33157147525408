import React, { useState } from "react";
import clsx from "clsx";
import { Text, Image } from "@atoms";
import { m } from "framer-motion";

const TimelineNode = ({
  heading,
  description,
  image,
  caption,
  reverse,
  intro,
}) => {
  const [show, setShow] = useState(false);
  const initial = !intro ? { opacity: 0, scale: 0.7, y: 40 } : {};
  const whileInView =
    !intro && show
      ? {
          opacity: 1,
          scale: 1,
          y: 0,
          transition: {
            duration: 0.8,
            delay: 0.1,
            easing: "anticipate",
          },
        }
      : "";

  return (
    <m.li
      className={clsx("relative grid grid-cols-2 gap-14", {
        "gap-y-12 md:gap-y-24": intro,
      })}
      onViewportEnter={() => setShow(true)}
      viewport={{ once: true }}
    >
      <div
        className={clsx("relative self-center px-2", {
          "col-start-2": intro,
        })}
      >
        <div
          aria-hidden="true"
          className={clsx(
            "absolute inset-y-0 my-auto h-[3px] w-4 bg-gold sm:w-6",
            { "-right-7": reverse, "-left-7": !reverse }
          )}
        />

        <m.div initial={initial} animate={whileInView}>
          <Text variant="labelMd" className="mb-4">
            {heading}
          </Text>
          <Text variant="xs">{description}</Text>
        </m.div>
      </div>
      <m.figure
        className={clsx({
          "order-first col-span-full": intro,
          "order-first": !reverse,
        })}
        initial={initial}
        animate={whileInView}
      >
        <div className="relative aspect-[239/159] w-full overflow-hidden rounded-lg bg-offwhite">
          {image && (
            <Image
              image={image}
              fill
              ixParams={{ w: 239, h: 159 }}
              preserveAspectRatio
            />
          )}
        </div>
        {caption && (
          <Text tag="figcaption" variant="caption" className="text-grey mt-3">
            {caption}
          </Text>
        )}
      </m.figure>
    </m.li>
  );
};

TimelineNode.defaultProps = {};

export default TimelineNode;
