import React from "react";
import { Container, Text, Button } from "@atoms";
import { Logo, FooterNav } from "@molecules";
import { AppLink } from "@base";
import useFooterData from "@staticQueries/FooterQuery";

const Footer = () => {
  const { privacyLink, cookieLink, copyright, nav } = useFooterData();
  return (
    <footer className="relative z-[90] mt-auto bg-black pb-10 text-white">
      <Container variant="xl">
        <div className="flex flex-col gap-10 sm:justify-between md:gap-20 lg:flex-row">
          {/* organization information */}
          <div className="flex max-w-xs flex-shrink-0 flex-col justify-between gap-10 pt-10">
            <div className="w-auto">
              <Logo fitHeight className="h-10" />
            </div>

            {/* copyright */}
            <div className="flex flex-col gap-1">
              <div className="mb-4">
                <Button
                  to="https://stanfordresearchpark.com/app/"
                  color="gold"
                  size="sm"
                >
                  Download App
                </Button>
              </div>
              <Text variant="xs" className="text-gold transition-colors">
                {/* todo: cheaper cleanup here */}
                {`&copy; ${new Date().getFullYear()} ${copyright.replace(
                  /<.*?>/g,
                  ""
                )}`}
              </Text>

              {/* privacy */}
              <div className="flex gap-0.5 text-gold" aria-hidden="true">
                {privacyLink?.url && (
                  <AppLink
                    className="text-xs text-gold underline-offset-2 transition-colors hover:text-white hover:underline focus:text-white"
                    to={privacyLink.url}
                  >
                    <Text variant="xs">{privacyLink.text}</Text>
                  </AppLink>
                )}
                {cookieLink?.url && (
                  <>
                    <Text variant="xs" className="text-gold">
                      /
                    </Text>
                    <AppLink
                      className="text-xs text-gold underline-offset-2 transition-colors hover:text-white hover:underline focus:text-white"
                      to={cookieLink.url}
                    >
                      <Text variant="xs">{cookieLink.text}</Text>
                    </AppLink>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* nav */}
          <div className="flex w-full md:justify-end">
            <FooterNav links={nav} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
