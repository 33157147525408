import React from "react";
import clsx from "clsx";
import { Button, Text } from "@atoms";
import HeadingOutlined from "./HeadingOutlined";

const HeadingButton = ({
  heading,
  description,
  button,
  color,
  border,
  serifDescription,
  largeDescription,
}) => {
  const headingColors = {};
  const buttonColors = {
    black: "transparent",
    gold: "transparentGold",
    lightGold: "burgundy",
    darkGold: "burgundy",
  };
  return (
    <div
      className={clsx("flex flex-col gap-10 md:flex-row", border, {
        "border-t pt-6": border,
        "justify-between": heading && button?.url,
      })}
    >
      <div
        className={clsx({
          "max-w-sm": heading && description && button?.url,
          "lg:min-w-[32rem]": heading && description && !button?.url,
          "max-w-3xl lg:pr-8": heading && !description,
        })}
      >
        <HeadingOutlined text={heading} color={headingColors[color] || color} />
      </div>
      {description && (
        <Text
          variant={!serifDescription ? "body" : "serifDesc"}
          className={clsx("w-full max-w-none ", {
            "text-white": color === "gold",
            "text-lg sm:text-1.5xl leading-tight": largeDescription,
            "md:max-w-sm": !largeDescription,
            "md:max-w-lg": largeDescription,
          })}
          richText
        >
          {description}
        </Text>
      )}
      {button?.url && (
        <div className="flex-shrink-0">
          <Button
            to={button.url}
            color={buttonColors[color] || color}
            icon="arrow"
          >
            {button.text}
          </Button>
        </div>
      )}
    </div>
  );
};

HeadingButton.defaultProps = {};

export default HeadingButton;
