import React, { useState } from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "@base";
import Text from "./Text";

const Input = ({
  label,
  hideLabel,
  type,
  placeholder,
  name,
  singleLine,
  formState,
  errorState,
  errorMessage,
  className: _className,
  value,
  required,
  options,
  id,
  group,
  autocomplete,
  color,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState([]);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset className={clsx(_className, { "text-red": hasError })}>
        {label && (
          <legend className={clsx("mb-4 flex gap-1", { "sr-only": hideLabel })}>
            <Text variant="buttonSm" className="flex">
              {label} {required && <span className="text-red">*</span>}
            </Text>
          </legend>
        )}
        {group?.map((_g, i) => {
          return (
            <div
              key={`${_g?.id || id}`}
              className={clsx({
                "mb-4 flex items-center": singleLine || _g?.singleLine,
                "mb-4 flex flex-row-reverse items-center justify-end":
                  type === "checkbox" || type === "radio",
              })}
            >
              <label
                htmlFor={_g?.id || name}
                className={clsx("block", {
                  "mb-2":
                    !singleLine && type !== "checkbox" && type !== "radio",
                  "ml-2": type === "checkbox" || type === "radio",
                  "mr-2": singleLine || _g?.singleLine,
                })}
              >
                {_g?.label}
              </label>
              <Field
                {..._g}
                id={_g?.id || name}
                name={_g?.name || name}
                value={_g.value}
                formState={[form, setForm]}
                hasError={hasError}
                checked={_g?.checked}
                groupState={
                  type === "checkbox" ? [checkedList, setCheckedList] : null
                }
                className=""
              />
            </div>
          );
        })}
        {errorMessage && hasError && (
          <Text variant="xs" className="mt-1 text-red">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={clsx(_className)}>
      <div
        className={clsx("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "text-red": hasError,
        })}
      >
        <label
          htmlFor={id || name}
          className={clsx("flex gap-1", {
            "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
            "mr-2": singleLine,
            "ml-2": type === "checkbox" || type === "radio",
            "sr-only": hideLabel,
            "text-gold": color === "gold",
          })}
        >
          <Text variant="buttonSm" className="flex flex-nowrap">
            {label} {required && <span className="text-red">*</span>}
          </Text>
        </label>

        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          className=""
          autocomplete={autocomplete}
          color={color}
        />
      </div>
      {errorMessage && hasError && (
        <Text variant="xs" className="mt-1 text-red">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

Input.defaultProps = {
  color: "black",
};

export default Input;
